<template>
  <layout-centered>
    <div class="px-4 py-5">
      <img class="d-block mx-auto mb-4" src="@/assets/img/ifs-logo-335x56.png" :alt="appTitle" width="335">

      <div v-if="!loading">
        <form @submit.prevent="onSignIn">
          <div class="mb-3">
            <label for="email">{{ $t('username') }}</label>
            <input id="email"
                   class="form-control"
                   type="text"
                   required="required"
                   :placeholder="$t('email')"
                   v-model="username">
          </div>

          <div class="mb-3">
            <label for="password">{{ $t('password') }}</label>
            <input id="password"
                   class="form-control"
                   type="password"
                   required="required"
                   :placeholder="$t('password')"
                   v-model="password">
          </div>
          <div class="mb-3 ms-1">
            <router-link class="text-dark text-decoration-none" :to="{ name: 'password-reset'}">
              {{ $t('forgot-your-password') }}
            </router-link>
          </div>

          <div class="text-center">
            <button class="btn btn-lg btn-primary" type="submit">
              <font-awesome-icon icon="sign-in-alt" class="me-2"/>
              {{ $t('sign-in') }}
            </button>
          </div>

        </form>
      </div>

      <div v-if="loading">
        <component-spinner class="p-5" type="spinner-grow"/>
      </div>

      <div class="alert alert-danger alert-dismissible fade show mt-3" role="alert" v-if="!loading && error">
        Invalid email or password.
        <button type="button" class="close" data-dismiss="alert" :aria-label="$t('close')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
  </layout-centered>
</template>

<script>

import LayoutCentered from "@/layouts/LayoutCentered";
import ComponentSpinner from "@/components/ui/ComponentSpinner";

export default {
  name: "PageSignIn",
  components: { ComponentSpinner, LayoutCentered },
  computed: {
    appTitle() {
      return process.env.VUE_APP_TITLE;
    }
  },
  data() {
    return {
      loading: false,
      error: false,
      username: '',
      password: ''
    }
  },
  methods: {
    async onSignIn() {
      this.loading = true;
      this.error = true;

      const payload = {
        username: this.username,
        password: this.password
      };

      this.$store.dispatch('ifs/signIn', payload)
          .then(() => {
            this.$router.push({ name: 'dashboard' });
          })
          .catch(error => {
            this.loading = false;
            this.error = true;
            Promise.reject(error);
          });
    }
  }
}
</script>

<style scoped lang="scss">
#sign-in-outer {
}
#sign-in-inner {
  margin-top: 10em;
  min-width: 400px;
}
</style>