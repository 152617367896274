<template>
  <div class="spinner-container d-flex justify-content-center">
    <div :class="[type, color, sizeClass]" role="status">
      <span class="sr-only"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ComponentSpinner",
  computed: {
    sizeClass() {
      const props = this.$props;

      return {
        'spinner-border-sm': props.type === 'spinner-border' && props.small === true,
        'spinner-grow-sm': props.type === 'spinner-grow' && props.small === true
      }
    }
  },
  props: {
    color: {
      type: String,
      default: 'text-dark',
      required: false
    },
    type: {
      type: String,
      default: 'spinner-border',
      required: false,
      validator: value => {
        return ['spinner-border', 'spinner-grow'].indexOf(value) !== -1
      }
    },
    small: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
</style>