<template>
  <div id="background" class="d-flex bg-light vh-100"
       :style="{ background: 'linear-gradient(315deg, rgba(13, 45, 128, .3), rgba(13, 45, 128, .7)), url(background-image.jpg) no-repeat' }">
    <div id="layout-center" class="bg-white shadow">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: "LayoutCentered"
}
</script>

<style scoped lang="scss">
#layout-center {
  width: 100%;
  max-width: 420px;
  margin: auto;
}

#background {
  -webkit-background-size: cover !important;
     -moz-background-size: cover !important;
       -o-background-size: cover !important;
          background-size: cover !important;
}
</style>